import React from "react";
import tw, { styled, css } from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { stripPhoneNumber } from "../../lib/functions";
import { useInView } from "react-intersection-observer";

import Form from "../components/Form";

const Div = styled.div(({ inview, profile }) => [
  tw`relative  flex-col items-center w-full duration-1000 ease-in-out py-16 px-20 opacity-0 before:(content[''] bg-dark top-0 left-0 w-full h-3 absolute)`,

  profile
    ? tw`translate-x-[-50px] hidden xl:flex`
    : tw`translate-x-[150px] flex`,
  css`
    box-shadow: 0 0 25px -5px rgb(0 0 0 / 0.1), 0 0 10px -6px rgb(0 0 0 / 0.1);
  `,
  inview && tw`translate-x-0 opacity-100`,
]);

const Title = tw.h3`font-bold text-3xl text-primary text-center mb-4`;
const Subtitle = tw.p`text-center`;
const Number = tw.a`text-primary font-gothic uppercase tracking-widest text-xl text-center duration-500 ease-in-out hover:text-gold mb-8`;

const fields = [
  {
    type: "text",
    required: true,
    label: "Name",
    id: "side-f-name",
    width: 1,
  },
  {
    type: "tel",
    required: true,
    label: "Phone Number",
    id: "side-f-phone",
    width: 1,
  },
  {
    type: "email",
    required: true,
    label: "E-mail",
    id: "side-f-email",
    width: 1,
  },
  {
    type: "textarea",
    required: true,
    label: "Brief Description of Your Case",
    id: "side-f-description",
    width: "full",
  },
];

const SideForm = ({ title, profile, ...rest }) => {
  const { mainNumber } = useStaticQuery(graphql`
    {
      wp {
        esquireAcf {
          firmInformation {
            mainNumber
          }
        }
      }
    }
  `).wp.esquireAcf.firmInformation;

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Div {...rest} ref={ref} profile={profile} inview={inView}>
      <Title>{title ? title : "Schedule Your Case Evaluation"}</Title>
      <Subtitle>Provide the information below or call us at: </Subtitle>
      <Number href={`tel:${stripPhoneNumber(mainNumber)}`}>{mainNumber}</Number>
      <Form fields={fields} cols={1} sidebar />
    </Div>
  );
};

export default SideForm;
