import { Link } from "gatsby";
import React from "react";
import tw, { styled } from "twin.macro";

const List = tw.ul`flex items-center justify-center`;

const Item = styled(Link)(({ current }) => [
  tw`hover:bg-gray-200 flex items-center justify-center w-8 h-8 mx-1 text-base font-bold text-center`,
  current && tw`bg-primary hover:bg-primary text-white`,
]);

const PaginationItem = ({ number, current, category, ...rest }) => {
  let categoryPath;
  category &&
    (categoryPath = number > 1 ? `${category}page/${number}/` : `${category}`);
  const blogPath = number > 1 ? `/blog/page/${number}/` : "/blog/";
  return (
    <li {...rest}>
      <Item current={current} to={category ? categoryPath : blogPath}>
        {number ? number : 1}
      </Item>
    </li>
  );
};

const Pagination = ({ pageNumber, numberOfPages, category, ...rest }) => {
  const numbers = new Array(11)
    .fill(pageNumber)
    .map((number, i) => number - 5 + i)
    .filter((val) => val > 0 && val <= numberOfPages);
  return (
    <List {...rest}>
      {!numbers.includes(1) && (
        <>
          <PaginationItem number={0} category={category} />
          <li>...</li>
        </>
      )}
      {numbers.map((number) => (
        <PaginationItem
          key={number}
          current={number === pageNumber}
          number={number}
          category={category}
        />
      ))}
      {!numbers.includes(numberOfPages) && (
        <>
          <li>...</li>
          <PaginationItem number={numberOfPages} category={category} />
        </>
      )}
    </List>
  );
};

export default Pagination;
