import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import tw from "twin.macro";
import { getBreadcrumbs } from "../../lib/functions";

import Layout from "../components/Layout";
import InnerTitleBlock from "../components/InnerTitleBlock";
import Pagination from "../components/Pagination";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SideForm from "../components/SideForm";
import Button from "../components/Button";
import YourTeam from "../components/YourTeam";
import CaseResults from "../components/CaseResults";
import Awards from "../components/Awards";
import FrontReviews from "../components/FrontReviews";

const PostTitle = tw(
  Link
)`text-4xl duration-500 ease-in-out hover:text-primary font-mazuis italic mb-1`;
const Date = tw.p`text-primary font-bold text-sm`;

const List = tw.ul`flex flex-col gap-12 items-start`;
const Single = tw.li`flex flex-col items-start`;

const Blog = ({ data, pageContext }) => {
  const seo = data.wpPage.seo;
  const { posts } = data.allWpPost;
  // Manually set the robots tag to never index paginated pages of blog.
  if (pageContext.pageNumber !== 0 || !!pageContext.category) {
    seo.metaRobotsNofollow = "nofollow";
    seo.metaRobotsNoindex = "noindex";
  } else {
    seo.metaRobotsNofollow = "follow";
    seo.metaRobotsNoindex = "index";
  }

  return (
    <Layout inner seo={seo}>
      <InnerTitleBlock
        header={
          pageContext.categoryName
            ? `Firm Blog: ${pageContext.categoryName}`
            : "Firm Blog"
        }
        breadcrumbs={getBreadcrumbs(
          seo,
          { url: pageContext.categoryUri, name: pageContext.categoryName },
          pageContext.pageNumber
        )}
      />
      <PageWrapper inner>
        <ContentWrapper>
          <List>
            {posts.map(({ uri, title, excerpt, date }, i) => (
              <Single key={i}>
                <PostTitle to={uri}>{title}</PostTitle>
                <Date>{date}</Date>
                <div tw="mt-2" dangerouslySetInnerHTML={{ __html: excerpt }} />
                <Button url={uri} tw="mt-4" blog>
                  Read More
                </Button>
              </Single>
            ))}
          </List>
          <Pagination
            tw="mt-12"
            category={pageContext.categoryUri}
            pageNumber={pageContext.humanPageNumber}
            numberOfPages={pageContext.numberOfPages}
          />
        </ContentWrapper>
        <SideWrapper>
          <SideForm />
          <Sidenavs showCategories />
        </SideWrapper>
      </PageWrapper>
      <FrontReviews />
      <YourTeam />
      <CaseResults />
      <Awards inner />
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery(
    $limit: Int!
    $skip: Int!
    $category: String
    $ignore: String
  ) {
    wpPage(isPostsPage: { eq: true }) {
      ...SEO
    }
    allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { id: { eq: $category, ne: $ignore } } }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      posts: nodes {
        ...PostList
      }
    }
  }
  fragment PostList on WpPost {
    title
    uri
    excerpt
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  }
`;

export default Blog;
