import React from "react";
import tw, { styled } from "twin.macro";

const Aside = styled.aside(({ front, profile }) => [
  tw`w-full max-w-[35rem] 2xl:max-w-[40rem] gap-40  relative  flex-col`,
  front && tw`justify-center`,
  profile ? tw`xl:pl-12 flex` : tw`xl:flex hidden pr-12`,
]);

const SideWrapper = ({ ...rest }) => {
  return <Aside {...rest}></Aside>;
};

export default SideWrapper;
