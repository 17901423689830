import React from "react";
import tw, { styled } from "twin.macro";

const Section = styled.section(({ inner }) => [
  tw`flex relative mt-16 sm:mt-32 gap-12 overflow-hidden flex-col items-center xl:(flex-row gap-0 mt-48 px-0 items-stretch) px-4 md:px-12 lg:px-20 mb-12 2xl:pb-20`,
  inner && tw`mt-0!`,
]);

const PageWrapper = ({ ...rest }) => {
  return <Section {...rest}></Section>;
};

export default PageWrapper;
