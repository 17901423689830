import React from "react";
import tw, { styled } from "twin.macro";

const Article = styled.article(({ profile }) => [
  tw`2xl:(pl-32) sm:pr-4 overflow-hidden w-full max-w-full xl:px-12 flex flex-col items-start relative`,
]);

const ContentWrapper = ({ children, ...rest }) => {
  return (
    <Article {...rest}>
      <div tw="max-w-[60rem] w-full">{children}</div>
    </Article>
  );
};

export default ContentWrapper;
